import { useState } from 'react';
import { Button, Container, Header, Form, Grid, Icon, Segment } from 'semantic-ui-react';

import useLongPress from './useLongPress';

import Agreement from '../components/Agreement';
import TextMessage from '../components/TextMessage';
import Spinner from '../components/Spinner';

export default function Onboarding(props) {
    const [hasPressed, setHasPressed] = useState(false);

    const onClick = () => {
    }

    const onLongPress = () => {
        setHasPressed(true);
    };

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 2000,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
    

    let output = '';
    if(props.currentCueNumber >= 30) { // Act 3
        output =
            <TextMessage
                icon={'chess board'}
                header={'Builders NFT Store'}
                content={'Work in Progress...'}
            />;
    } else if(props.currentCueNumber >= 20) {
        if(hasPressed === false) {
            output =
                <Grid style={{ height: '80vh', margin: '0' }} textAlign='center'>
                    <Grid.Column style={{ maxWidth: '500px' }}>
                        <Header as='h3' attached='top'>Please Verify You are a Human</Header>
                        <Segment padded attached>
                            <img src='./img/the-builders-logo-black.png' width='200px' />
                            <Header as='h4'>Welcome Human Worker {props.userData.id}</Header>
                            <p>Your account has been migrated to the Builders HIT Marketplace!</p>
                            <div style={{height: '10px'}}></div>
                            <Button size='big' color='red' content='Press and Hold' {...longPressEvent} />
                        </Segment>
                    </Grid.Column>
                </Grid>;
        } else {
            output =
            <Agreement
                setHasAgreed={() => props.updateUserData('onboarding', 1)}
            />
        }
    } else if(props.currentCueNumber >= 10) {
        output =
            <SetName
                updateUserData={props.updateUserData}
                saveName={props.saveName}
            />;
    }

    return (
        <Container text>
            {output}
        </Container>
    )
}

function SetName(props) {
    const [name, setName] = useState('');

    const save = () => {
        props.saveName(name);
        props.updateUserData('onboarding', 0);
    }

    let button = (name === '' || name === undefined) ?
        <Button disabled fluid color='blue' size='large' onClick={save} style={{marginTop: '10px'}}>Login</Button> :
        <Button fluid color='blue' size='large' onClick={save} style={{marginTop: '10px'}}>Login</Button>;

    return (
        <Grid style={{ height: '80vh', margin: '0' }} textAlign='center'>
            <Grid.Column style={{ maxWidth: '400px' }}>
                <Segment>
                    <Header as='h3' textAlign='center'>Welcome to your personalized Builders second screen account. Please enter your preferred name for use in the show.</Header>
                    <p style={{textAlign: 'center'}}>Also use this name in your Zoom window. We can help you do that if you don’t know how, during the pre-show. Just ask in the chat!</p>
                    <Form.Input
                        fluid icon='user'
                        iconPosition='left'
                        placeholder='Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    {button}
                </Segment>
            </Grid.Column>
        </Grid>
    )
}