import { useState } from 'react';
import { Button, Container, Form, Header } from 'semantic-ui-react';
import firebase from 'firebase/app';

const BREAKOUTROOMNAME = [
    'Sybil',
    'Neol',
    'Michelle',
    'Adah',
    'All'
]

export default function RankingSetting(props) {
    const [input, setInput] = useState(props.groupInput);

    const updateInput = (index, value) => {
        setInput(existingItems => {
            return [
                ...existingItems.slice(0, index),
                value,
                ...existingItems.slice(index + 1),
            ]
        })
    }

    const save = () => {
        let groupList = [[],[],[],[],[]]
        for(let i = 0; i < input.length; i++) {
            groupList[i] = input[i].split(',').map(item => item.trim());
        }
        firebase.database().ref(`public/groupInput`).set(input);
        firebase.database().ref(`public/groupList`).set(groupList);
    }

    let formFields = [];
    for(let i = 0; i < input.length; i++) {
        formFields.push(
            <Form.Field key={i}>
                <Form.TextArea
                    label={`Breakout Room - ${BREAKOUTROOMNAME[i]}`}
                    placeholder={'giada1198@gmail.com, nickwauer@gmail.com, kdwyer596@gmail.com'}
                    value={input[i]}
                    onChange={(e, {value}) => updateInput(i, value)}
                />
            </Form.Field>
        );
    }
    
    return(
        <Container text>
            <Header as='h2' textAlign='center'>Audience Ranking Setting</Header>
            <Form>
                {formFields}
                <Button fluid type='submit' color='blue' onClick={save}>Save</Button>
			</Form>
        </Container>
    );
}