import { useState, Fragment } from 'react';
import { Button, Dropdown, Form, Grid, Header, Message, Segment, Tab } from 'semantic-ui-react';
import { getQueryStringValue } from '../components/QueryString';

import TopMenu from '../components/TopMenu';
// import Captcha from '../components/Captcha';

export default function Login(props) {
    // const [isCaptcha, setIsCaptcha] = useState(false);
    const [activeIndex, setActiveIndex] = useState(props.signupSession !== null ? 0 : 1);

    const [loginEmail, setLoginEmail] = useState(getQueryStringValue('email'));
    const [loginPassword, setLoginPassword] = useState(getQueryStringValue('id'));

    const [signupEmail, setSignupEmail] = useState('');
    const [signupPassword, setSignupPassword] = useState('');

    const [signupSession, setSignupSession] = useState(props.signupSession !== null ? 
        7 <= props.signupSession && props.signupSession <= 13 ? props.signupSession : props.constSession : props.constSession);
    
    const panes = [
        { menuItem: 'Sign Up', render: () => 
            <SignUpPanel
                signupSession={signupSession}
                setSignupSession={setSignupSession}
                signupEmail={signupEmail}
                setSignupEmail={setSignupEmail}
                signupPassword={signupPassword}
                setSignupPassword={setSignupPassword}
                signUp={props.signUp}
                errorMessage={props.errorMessage}
            />
        },
        { menuItem: 'Log In', render: () => 
            <LoginPanel
                loginEmail={loginEmail}
                setLoginEmail={setLoginEmail}
                loginPassword={loginPassword}
                setLoginPassword={setLoginPassword}
                signIn={props.signIn}
                errorMessage={props.errorMessage}
            />
        },
    ]

    // const showCaptcha = () => setIsCaptcha(true);
    const isAdmin = props.userData === null || props.userData === undefined ? false : props.userData['role'] === 'admin';
    const isBBS = props.userData !== null && props.userData !== undefined && props.currentCueNumber < 20 && props.userData['onboarding'] !== undefined && props.userData['onboarding'][0] && props.publicData !== null && (props.userData['session'] === props.publicData['session'] || props.userData['session'] === 0);
    const handleTabChange = (e, { activeIndex }) => {
        setActiveIndex(activeIndex);
        props.setErrorMessage('');
    }

    return (        
        <Fragment>
            {/* { isCaptcha ? (<Captcha signIn={signIn}/>) : null } */}
            { props.user ?
                <TopMenu
                    isAdmin={isAdmin}
                    isBBS={isBBS}
                    signOut={props.signOut}
                    changeAdminPage={props.changeAdminPage}
                /> :
                <Grid style={{ height: '100%', margin: '0' }} textAlign='center' verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: '450px' }}>
                    <Tab
                        panes={panes}
                        activeIndex={activeIndex}
                        onTabChange={handleTabChange}
                    />
                    </Grid.Column>
                </Grid>
            }
        </Fragment>
    )
}

function SignUpPanel(props) {
    
    const signupButton = (props.signupEmail === '' || props.signupEmail === undefined || props.signupPassword === '' || props.signupPassword === undefined) ?
        <Button disabled color='blue' fluid size='large'>Sign Up</Button> :
        <Button color='blue' fluid size='large' onClick={() => props.signUp(props.signupEmail, props.signupPassword, props.signupSession)}>Sign Up</Button>;
    
    const sessions = [
        { key: 0, text: 'Show 7 (Apr 1, Fri @ 2:00pm)', value: 7 },
        { key: 1, text: 'Show 8 (Apr 1, Fri @ 5:00pm)', value: 8 },
        { key: 2, text: 'Show 9 (Apr 2, Sat @ 2:00pm)', value: 9 },
        { key: 3, text: 'Show 10 (Apr 2, Sat @ 5:00pm)', value: 10 },
        { key: 4, text: 'Show 11 (Apr 3, Sun @ 2:00pm)', value: 11 },
        { key: 5, text: 'Show 12 (Apr 3, Sun @ 5:00pm)', value: 12 },
        { key: 6, text: 'Show 13 (Demo @ Anytime)', value: 13 },
    ];

    console.log(props.errorMessage);

    return (
        <Tab.Pane style={{ padding: '20px' }}>
            <img src='./img/the-builders-logo-black.png' width='150px' />
            <Header as='h2' color='blue' textAlign='center' style={{ marginTop: '5px' }}>Create a Builders Account</Header>
            {
                props.errorMessage !== '' ?
                    <Message negative>
                        <p>{props.errorMessage}</p>
                    </Message> :
                    null
            }
            <Form size='large'>
                <Form.Field>
                    <label style={{textAlign: 'left'}}>Email</label>
                    <Form.Input
                        fluid icon='mail' 
                        iconPosition='left'
                        placeholder='Email'
                        value={props.signupEmail || ''}
                        onChange={(e) => props.setSignupEmail(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label style={{textAlign: 'left'}}>Password</label>
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Use 123456, it must be 6 digits!'
                        value={props.signupPassword || ''}
                        onChange={(e) => props.setSignupPassword(e.target.value)}
                        type='password'
                    />
                </Form.Field>
                <Form.Field>
                    <label style={{textAlign: 'left'}}>Confirm Your Show Time</label>
                    <Dropdown
                        fluid
                        selection
                        options={sessions}
                        value={props.signupSession}
                        onChange={(e, {value}) => props.setSignupSession(value)}
                        style={{ marginBottom: '25px' }}
                    />
                </Form.Field>
                {signupButton}
            </Form>
            {/* <Message>
                Have trouble logging in? Contact <b>Larry</b> in the Zoom chat.
            </Message> */}
        </Tab.Pane>
    )
}

function LoginPanel(props) {
    const loginButton = (props.loginEmail === '' || props.loginEmail === undefined || props.loginPassword === '' || props.loginPassword === undefined) ?
        <Button disabled color='blue' fluid size='large'>Login</Button> :
        <Button color='blue' fluid size='large' onClick={() => props.signIn(props.loginEmail, props.loginPassword)}>Login</Button>;

    return (
        <Tab.Pane style={{ padding: '20px' }}>
            <img src='./img/the-builders-logo-black.png' width='150px' />
            <Header as='h2' color='blue' textAlign='center' style={{ marginTop: '5px' }}>Log-in to Builders Account</Header>
            {
                props.errorMessage !== '' ?
                    <Message negative>
                        <p>{props.errorMessage}</p>
                    </Message> :
                    null
            }
            <Form size='large'>
                <Form.Field>
                    <label style={{textAlign: 'left'}}>Email</label>
                    <Form.Input
                        fluid icon='mail' 
                        iconPosition='left'
                        placeholder='Email'
                        value={props.loginEmail || ''}
                        onChange={(e) => props.setLoginEmail(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label style={{textAlign: 'left'}}>Password</label>
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        value={props.loginPassword || ''}
                        onChange={(e) => props.setLoginPassword(e.target.value)}
                        type='password'
                    />
                </Form.Field>
                {loginButton}
            </Form>
            {/* <Message>
                Have trouble logging in? Contact <b>Larry</b> in the Zoom chat.
            </Message> */}
        </Tab.Pane>
    )
}