import { Container, Message } from 'semantic-ui-react';

export default function TextMessage(props) {
	return (
		<Container text>
            <Message
				icon={props.icon}
				header={props.header}
				content={props.content}
			/>
        </Container>
	);
}