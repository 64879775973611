import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/database';
import 'firebase/storage';

const firebaseConfig = {
	apiKey: "AIzaSyAyU4ALU9Xl5yEYvEuqQRRzBtPOgdItFFc",
	authDomain: "i-agree-to-the-terms-2022.firebaseapp.com",
	databaseURL: "https://i-agree-to-the-terms-2022-default-rtdb.firebaseio.com",
	projectId: "i-agree-to-the-terms-2022",
	storageBucket: "i-agree-to-the-terms-2022.appspot.com",
	messagingSenderId: "286948150351",
	appId: "1:286948150351:web:edacdc7ff7ad4b30c1ee3a",
	measurementId: "G-K3REDEMQ1P"
};

// initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();