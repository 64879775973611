import { Button, Container, Dropdown, Menu } from 'semantic-ui-react';
import '../css/TopMenu.css';

export default function TopMenu(props) {
	let dropdown = props.isAdmin ?
		<Dropdown item simple text='Admin'>
			<Dropdown.Menu>
				<Dropdown.Item as='a' onClick={() => props.changeAdminPage('cue-list')}>
					Cue List
				</Dropdown.Item>

				<Dropdown.Item as='a' onClick={() => props.changeAdminPage('audience-list')}>
					Audience List
				</Dropdown.Item>

				<Dropdown.Item>
					<i className='dropdown icon' />
					<span className='text'>Audience Ranking</span>
					<Dropdown.Menu>
						{/* <Dropdown.Item as='a' onClick={() => props.changeAdminPage('ranking-1')}>
							Breakout Room - Sybil
						</Dropdown.Item>
						<Dropdown.Item as='a' onClick={() => props.changeAdminPage('ranking-2')}>
							Breakout Room - Noel
						</Dropdown.Item>
						<Dropdown.Item as='a' onClick={() => props.changeAdminPage('ranking-3')}>
							Breakout Room - Michelle
						</Dropdown.Item>
						<Dropdown.Item as='a' onClick={() => props.changeAdminPage('ranking-4')}>
							Breakout Room - Adah
						</Dropdown.Item> */}
						<Dropdown.Item as='a' onClick={() => props.changeAdminPage('ranking-5')}>
							Breakout Room - All
						</Dropdown.Item>
						{/* <Dropdown.Divider />
						<Dropdown.Item as='a' onClick={() => props.changeAdminPage('ranking-setting')}>
							Setting
						</Dropdown.Item> */}
					</Dropdown.Menu>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown> : null;
	
	let output = (props.isBBS) ?
			<div className='bbs-header'>
				<div className='bbs-header-container'>
					<p>BUILDERS BBS</p>
					<p className='log-out-button' onClick={props.signOut}>[LOG OUT]</p>
				</div>
			</div> :
			<Menu borderless inverted size='large'>
				<Container>
					<img src='/img/the-builders-logo-white.png' alt='' width='78px' height='30px' style={{ margin: '1em' }} />
					{dropdown}
					<Menu.Item position='right'>
						<Button compact inverted size='small' onClick={props.signOut}>Log Out</Button> 
					</Menu.Item>
				</Container>
			</Menu>;

    return output
}