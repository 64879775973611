import React, { useState, useEffect, Fragment } from 'react';
// import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { getQueryStringValue } from './components/QueryString';
import QRious from 'qrious';

import Login from './containers/Login';
import Onboarding from './containers/Onboarding';
import BuildersBBS from './containers/BuildersBBS';
import Lobby from './containers/Lobby';
import Hit from './containers/Hit';
import MetaMarketplace from './containers/MetaMarketplace';

import CueList from './containers/CueList';
import AudienceList from './containers/AudienceList';
import Ranking from './containers/Ranking';
import RankingSetting from './containers/RankingSetting';

import Spinner from './components/Spinner';
import TextMessage from './components/TextMessage';

import './App.css';
import data from './data/data-new.json';
import cueList from './data/cue-list-new.json';
import accounts from './data/account.json';

// const CREATEACCOUNT = false;
const SESSION = 13;

export default function App() {
	const [user, setUser] = useState(null);
	const [userData, setUserData] = useState(null);
	const [usersData, setUsersData] = useState(null);
	const [publicData, setPublicData] = useState(null);

	const [currentCueNumber, setCurrentCueNumber] = useState(null);
	const [currentHit, setCurrentHit] = useState('none');

	const [page, setPage] = useState('login');
	const [adminPage, setAdminPage] = useState('cue-list');

	const [errorMessage, setErrorMessage] = useState('');

	const signUp = (email, password, signupSession) => {
		firebase.auth().createUserWithEmailAndPassword(email, password)
			.then((userCredential) => {
				// Signed in 
				var user = userCredential.user;
				console.log('User signs in: ' + user.uid);
				// Create Account
				let storageRef = firebase.storage().ref();
				let qr = new QRious({
					value: `https://show.iagreetotheterms.com/?email=${user.email}&id=${password}}`
				});
				let ref = storageRef.child(`qrCodes/${user.uid}.png`);
				let string = qr.toDataURL();
				ref.putString(string, 'data_url').then((snapshot) => {
					console.log('Uploaded a base64 string!');
					ref.getDownloadURL()
						.then((url) => {
							let u = {
								email: user.email,
								password: password,
								realName: user.email.split("@")[0],
								name: user.email.split("@")[0],
								id: Math.floor(100000 + Math.random() * 900000),
								session: signupSession,
								role: 'worker',
								page: 'signed-in',
								hitsCompleted: ['nothing'],
								hitsRejected: ['nothing'],
								lastSentDate: '-',
								qrCode: url,
								// new
								hitsCompleted: ['nothing'],
								qualificationHitsCompleted: ['nothing'],
								hitsRejected: ['nothing'],
								money: 0.0,
								question: '',
								onboarding: [false, false, false]
							};
							firebase.database().ref(`users/${user.uid}`).set(u);
						})
						.catch((error) => {
							// Handle any errors
						});
				});
				setUser(user);
			})
			.catch((error) => {
				if (error.code === 'auth/email-already-in-use') {
                    setErrorMessage('The email is already in use. Please try another one.');
                } else if (error.code === 'auth/weak-password') {
                    setErrorMessage('Passwords must be 6 digits.');
                } else {
                    setErrorMessage('An error occurred. Please try again.');
                }
			});
	}

	const signIn = (email, password) => { // a callback function for logging in existing users
		// setState({ errorMessage: null }); // clear any old errors
		firebase.auth().signInWithEmailAndPassword(email, password)
			.then((userCredential) => {
				// signed in
				let user = userCredential.user;
				console.log('User signs in: ' + user.uid);
				console.log(user);
				
			})
			.catch((error) => {
				if(error.code === 'auth/wrong-password') {
                    setErrorMessage('The password is incorrect.');
                } else if(error.code === 'auth/user-not-found') {
                    setErrorMessage('The email is not found. Please sign up a new one.');
                } else {
                    setErrorMessage('An error occurred. Please try again.');
                }
			});
  	}

  	const signOut = () => { // a callback function for logging out the current user
		// setState({ errorMessage: null }); // clear any old errors
		firebase.auth().signOut()
			.catch((error) => {
				console.log(error.message);
				// setState({ errorMessage: error.message });
			});
		setUser(null);
		setUserData(null);
  	}

	// const createAccounts = () => {
	// 	let storageRef = firebase.storage().ref();
	// 	for(let i = 0 ; i < accounts['email'].length ; i++) {
	// 		// Create a root reference
	// 		let qr = new QRious({
	// 			value: `https://show.iagreetotheterms.com/?email=${accounts['email'][i]}&id=${accounts['id'][i]}`
	// 		});
	// 		let ref = storageRef.child(`qrCodes/${accounts['uid'][i]}.png`);
	// 		let string = qr.toDataURL();
	// 		ref.putString(string, 'data_url').then((snapshot) => {
	// 			console.log('Uploaded a base64 string!');
	// 			ref.getDownloadURL()
	// 				.then((url) => {
	// 					let u = {
	// 						email: accounts['email'][i],
	// 						realName: accounts['realName'][i],
	// 						name: accounts['email'][i].split("@")[0],
	// 						id: accounts['id'][i],
	// 						session: SESSION,
	// 						role: 'worker',
	// 						page: 'new',
	// 						hitsCompleted: ['nothing'],
	// 						hitsRejected: ['nothing'],
	// 						lastSentDate: '-',
	// 						qrCode: url
	// 					};
	// 					if(accounts['realEmail'][i] !== 0) u['realEmail'] = accounts['realEmail'][i];
	// 					firebase.database().ref(`users/${accounts['uid'][i]}`).set(u);
	// 				})
	// 				.catch((error) => {
	// 					// Handle any errors
	// 				});
	// 		});
	// 	}
	// }
	
	useEffect(() => {
		// if(CREATEACCOUNT) {
		// 	console.log('create accounts!');
		// 	createAccounts();
		// }
		const authUnregFunc = firebase.auth().onAuthStateChanged((user) => {
			if(user) {
				console.log('logged in');
				setUser(user);
				firebase.database().ref('users').on('value', (snapshot) => {
					let d = snapshot.val();
					setUserData(d[user.uid]);
					if(d !== null && d[user.uid] !== undefined) {
						if(d[user.uid]['role'] === 'admin') {
							setUsersData(d);
							setPage(d[user.uid]['page']);
						} else if(d[user.uid]['role'] === 'worker') {
							// console.log('user role: ' + d[user.uid]['role']);
							setPage(d[user.uid]['page']);
							// create initial firebase data for new users
							if(d[user.uid]['page'] === 'new') {
								firebase.database().ref(`users/${user.uid}/name`).set(d[user.uid].email.split("@")[0]);
								firebase.database().ref(`users/${user.uid}/hitsCompleted`).set(['nothing']);
								firebase.database().ref(`users/${user.uid}/qualificationHitsCompleted`).set(['nothing']);
								firebase.database().ref(`users/${user.uid}/hitsRejected`).set(['nothing']);
								firebase.database().ref(`users/${user.uid}/money`).set(0.0);
								firebase.database().ref(`users/${user.uid}/question`).set('');
								firebase.database().ref(`users/${user.uid}/onboarding`).set([false, false, false]);
								setTimeout(() => {
									firebase.database().ref(`users/${user.uid}/page`).set('signed-in');
								}, 1000)
							}
						}
					}
				});
				// create public data listener
				firebase.database().ref('public').on('value', (snapshot) => {
					let d = snapshot.val();
					setPublicData(d);
					setCurrentCueNumber(cueToCueNumber(d['cue']));
				});
			}
			else { // firebaseUser undefined: is not logged in
				console.log('logged out');
				firebase.database().ref('users').off('value');
				firebase.database().ref('public').off('value');
			}
		});

		// Specify how to clean up after this effect:
		return () => {
			authUnregFunc();
			firebase.database().ref('users').off('value');
		};
	}, []);

	const cueToCueNumber = (c) => {
		return cueList['cues'][c]['no'];
	};

	const changeCue = (q) => {
		if(userData['role'] === 'admin') {
			firebase.database().ref('public/cue').set(q);
		}
	}

	const changeAdminPage = (p) => setAdminPage(p);
	const submitHit = () => setCurrentHit('none');
	const saveName = (name) => firebase.database().ref(`users/${user.uid}/name`).set(name);

	// const saveQuestion = (q) => {
	// 	firebase.database().ref(`users/${user.uid}/question`).set(q);
	// };

	const updateUserData = (type, value) => {
		if(type === 'hitsCompleted') {
			let updatedData = [...userData['hitsCompleted']];
			typeof value === 'object' ? updatedData = updatedData.concat(value) : updatedData.push(value);
			firebase.database().ref(`users/${user.uid}/hitsCompleted`).set(updatedData);
		} else if(type === 'hitsRejected' && value !== '') {
			let updatedData = [...userData['hitsRejected']];
			typeof value === 'object' ? updatedData = updatedData.concat(value) : updatedData.push(value);
			firebase.database().ref(`users/${user.uid}/hitsRejected`).set(updatedData);
		} else if(type === 'qualificationHitsCompleted' && value !== '') {
			let updatedData = [...userData['qualificationHitsCompleted']];
			typeof value === 'object' ? updatedData = updatedData.concat(value) : updatedData.push(value);
			firebase.database().ref(`users/${user.uid}/qualificationHitsCompleted`).set(updatedData);
		} else if(type === 'money') {
			firebase.database().ref(`users/${user.uid}/money`).set(userData['money']+value);
		} else if(type === 'onboarding') {
			let ob = userData['onboarding'];
			ob[value] = true;
			firebase.database().ref(`users/${user.uid}/onboarding`).set(ob);
		}
	}

	let output = null;
	if(user && userData !== null && userData !== undefined && publicData !== null) {
		if(userData['role'] === 'admin') {
			if(page === 'dashboard') {
				switch(adminPage) {
					case 'cue-list':
						output = 
							<Fragment>
								<CueList
									userData={userData}
									publicData={publicData}
									changeCue={changeCue}
								/>
								<Footer />
							</Fragment>;
						break;
					case 'audience-list':
						output = 
							<Fragment>
								<AudienceList
									currentSession={publicData.session}
									usersData={usersData}
								/>;
								<Footer />
							</Fragment>;
						break;
					case 'ranking-setting':
						output =
							<Fragment>
								<RankingSetting groupInput={publicData.groupInput} />
								<Footer />
							</Fragment>;
						break;
					case 'ranking-1':
						output = <Ranking groupList={publicData.groupList[0]} currentCueNumber={currentCueNumber} usersData={usersData} session={publicData.session} />
						break;
					case 'ranking-2':
						output = <Ranking groupList={publicData.groupList[1]} currentCueNumber={currentCueNumber} usersData={usersData} session={publicData.session} />
						break;
					case 'ranking-3':
						output = <Ranking groupList={publicData.groupList[2]} currentCueNumber={currentCueNumber} usersData={usersData} session={publicData.session} />
						break;
					case 'ranking-4':
						output = <Ranking groupList={publicData.groupList[3]} currentCueNumber={currentCueNumber} usersData={usersData} session={publicData.session} />
						break;
					case 'ranking-5':
						output = <Ranking groupList={publicData.groupList[4]} currentCueNumber={currentCueNumber} usersData={usersData} session={publicData.session} />
						break;
					default:
						output = '';
				}
			}			
		} else if(userData['role'] === 'worker' || userData['role'] === 'invisible-worker') {
			if(userData['session'] !== 0 && userData['session'] !== publicData['session'] && userData['role'] !== 'invisible-worker') {
				output =
					<TextMessage
						icon={'calendar alternate outline'}
						header={'Your session has not started yet.'}
						content={'Please come back later.'}
					/>;
			} else {
				if(page === 'new') {
					output = <Spinner />;
				} else if(page === 'signed-in') {
					if(currentCueNumber === 20) {
						output = 
							<Grid style={{ height: '80vh', margin: '0' }} textAlign='center'>
								<Grid.Column style={{ maxWidth: '500px' }}>
									<Segment padded>
										<Spinner />
										<Header as='h3'>We are converting your account to<br/>the Builders HIT Marketplace</Header>
										<p>Please standby</p>
									</Segment>
								</Grid.Column>
							</Grid>;
					} else if(currentCueNumber < 20) {
						if(userData['onboarding'][0] === false) {
							output = 
								<Onboarding
									user={user}
									userData={userData}
									currentCueNumber={currentCueNumber}
									updateUserData={updateUserData}
									saveName={saveName}
								/>;
						} else {
							output =
								<BuildersBBS
									name={userData['name']}
									currentCueNumber={currentCueNumber}
								/>;
						}
					} else if(currentCueNumber < 30) {
						if(userData['onboarding'][1] === false) {
							output =
								<Onboarding
									user={user}
									userData={userData}
									currentCueNumber={currentCueNumber}
									updateUserData={updateUserData}
									saveName={saveName}
								/>;
						} else {
							if(currentHit !== 'none') {
								output =
									<Hit
										questions={data[currentHit]}
										submit={submitHit}
										updateUserData={updateUserData}
									/>;
							} else { 
								output =
									<Fragment>
										<Lobby
											key={'lobby'}
											data={data}
											userData={userData}
											currentCueNumber={currentCueNumber}
											changeHit={setCurrentHit}
										/>
										<Footer />
									</Fragment>;
							}
						}
					} else if(currentCueNumber === 30) {
						output =
							<Grid style={{ height: '80vh', margin: '0' }} textAlign='center'>
								<Grid.Column style={{ maxWidth: '500px' }}>
									<Segment padded>
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
											<img src='./img/usd-icon.png' alt='' height='75px'/>
											<Icon size='large' name='angle double right' style={{ margin: '10px' }} />
											<img src='./img/bldrs-icon.png' alt='' height='75px'/>
										</div>
										<Header as='h3'>Updating your account to the Builders Bazaar Marketplace - On META</Header>
										<Icon size='big' loading name='spinner' style={{ margin: '5px 0 15px 0' }} />
										<p>Your Dollars are being converted to BLDR Coin — and we’re waiving the transaction fee!</p>
									</Segment>
								</Grid.Column>
							</Grid>;
					} else if(currentCueNumber === 30.5) {
						output =
							<Grid style={{ height: '80vh', margin: '0' }} textAlign='center'>
								<Grid.Column style={{ maxWidth: '500px' }}>
									<Segment padded>
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
											<img src='./img/usd-icon.png' alt='' height='75px'/>
											<Icon size='large' name='angle double right' style={{ margin: '10px' }} />
											<img src='./img/bldrs-icon.png' alt='' height='75px'/>
										</div>
										<Header as='h3'>Manifesto 4</Header>
										<Icon size='big' loading name='spinner' />
										<Header as='h3'>Mark Zuckerberg (2022)</Header>
									</Segment>
								</Grid.Column>
							</Grid>;
					} else if(currentCueNumber === 31) {
						output =
							<Fragment>
								<MetaMarketplace
									money={userData.money}
								/>
								<Footer />
							</Fragment>;
					} else if(currentCueNumber === 32) {
						output =
							<TextMessage
								header={'The Builders Bazaar Marketplace is closed'}
								content={'We will be back soon.'}
							/>;
					}
				}
			}
		}
	}

	return (
		<div className="App">
			<Login
				user={user}
				userData={userData}
				currentCueNumber={currentCueNumber}
				signUp={signUp}
				signIn={signIn}
				signOut={signOut}
				changeAdminPage={changeAdminPage}
				signupSession={getQueryStringValue('session') ? parseInt(getQueryStringValue('session')) : null}
				constSession={SESSION}
				publicData={publicData}
				errorMessage={errorMessage}
				setErrorMessage={setErrorMessage}
			/>
			{output}
		</div>
	);
}

function Footer() {
	return (
		<Segment secondary as="footer">
			<Container textAlign="center">
				<p>© 2022 The Builders Association.</p>
			</Container>
		</Segment>
	)
}