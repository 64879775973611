import { useState, useEffect } from 'react';
import { Button, Container, Dropdown, Header, Icon, Menu, Table } from 'semantic-ui-react';

import firebase from 'firebase/app';
import { Fragment } from 'react/cjs/react.production.min';

const SESSION = [
    'Rehearsal @ 7:00pm',
    'Mar 25, Fri @ 2:00pm',
    'Mar 25, Fri @ 5:00pm',
    'Mar 26, Sat @ 2:00pm',
    'Mar 26, Sat @ 5:00pm',
    'Mar 27, Sun @ 2:00pm',
    'Mar 27, Sun @ 5:00pm',
    'Apr 1, Fri @ 2:00pm',
    'Apr 1, Fri @ 5:00pm',
    'Apr 2, Sat @ 2:00pm',
    'Apr 2, Sat @ 5:00pm',
    'Apr 3, Sun @ 2:00pm',
    'Apr 3, Sun @ 5:00pm',
    'Demo @ Anytime'
];

const PERFORMANCELINK = [
    'https://us02web.zoom.us/j/81470166581?pwd=ejVreTF3Qml6blE2SHJEV21rdG4zdz09',   // Session 0 
    'https://us02web.zoom.us/j/87660710686?pwd=R0dyZVhya0dMa2ZlSnAyczZyM3o3UT09',   // Session 1, Mar 25, Fri @ 2:00pm
    'https://us02web.zoom.us/j/85337399583?pwd=Zlg5OXJ0MFlycmRSRndnNk9JOE5Ldz09',   // Session 2, Mar 25, Fri @ 5:00pm
    'https://us02web.zoom.us/j/82730030862?pwd=TFBoOFZvY3JUNy9TdSt0WjBUOFo0QT09',   // Session 3, Mar 26, Sat @ 2:00pm
    'https://us02web.zoom.us/j/89309561551?pwd=bWZZTUNiL3pibW9veFdVem5JeDRQZz09',   // Session 4, Mar 26, Sat @ 5:00pm
    'https://us02web.zoom.us/j/86361999187?pwd=aXJKQzk2Y3JiOHpBam5tOGtVVTBRdz09',   // Session 5, Mar 27, Sun @ 2:00pm
    'https://us02web.zoom.us/j/84356879883?pwd=VUJwYlVBeTBGWmxUeGFnU1JqU0JGZz09',   // Session 6, Mar 27, Sun @ 5:00pm
    'https://thebuildersassociation.org/',                                          // Session 7, Apr 1, Fri @ 2:00pm
    'https://thebuildersassociation.org/',                                          // Session 8, Apr 1, Fri @ 5:00pm
    'https://thebuildersassociation.org/',                                          // Session 9, Apr 2, Sat @ 2:00pm
    'https://thebuildersassociation.org/',                                          // Session 10, Apr 2, Sat @ 5:00pm
    'https://thebuildersassociation.org/',                                          // Session 11, Apr 3, Sun @ 2:00pm
    'https://thebuildersassociation.org/',                                          // Session 12, Apr 3, Sun @ 5:00pm
    'https://thebuildersassociation.org/'                                           // Session 13
]

export default function AudienceList(props) {

    const sessions = [
        { key:  0, text: 'Session 0 (Rehearsal @ 7:00pm)',      value: 0 },
        { key:  1, text: 'Session 1 (Mar 25, Fri @ 2:00pm)',    value: 1 },
        { key:  2, text: 'Session 2 (Mar 25, Fri @ 5:00pm)',    value: 2 },
        { key:  3, text: 'Session 3 (Mar 26, Sat @ 2:00pm)',    value: 3 },
        { key:  4, text: 'Session 4 (Mar 26, Sat @ 5:00pm)',    value: 4 },
        { key:  5, text: 'Session 5 (Mar 27, Sun @ 2:00pm)',    value: 5 },
        { key:  6, text: 'Session 6 (Mar 27, Sun @ 5:00pm)',    value: 6 },
        { key:  7, text: 'Session 7 (Apr 1, Fri @ 2:00pm)',     value: 7 },
        { key:  8, text: 'Session 8 (Apr 1, Fri @ 5:00pm)',     value: 8 },
        { key:  9, text: 'Session 9 (Apr 2, Sat @ 2:00pm)',     value: 9 },
        { key: 10, text: 'Session 10 (Apr 2, Sat @ 5:00pm)',    value: 10 },
        { key: 11, text: 'Session 11 (Apr 3, Sun @ 2:00pm)',    value: 11 },
        { key: 12, text: 'Session 12 (Apr 3, Sun @ 5:00pm)',    value: 12 },
        { key: 13, text: 'Session 13 (Demo @ Anytime)',         value: 13 }
    ];

    let sessionAudienceList = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]];

    const changeSession = (n) => {
        firebase.database().ref(`public/session`).set(n);
    }

    const sendEmail = (user) => {
        console.log(user['realEmail'] === undefined ? user['email'] : user['realEmail']);
        firebase.firestore().collection('mail_collection').add({
            to: user['realEmail'] === undefined ? user['email'] : user['realEmail'],
            message: {
                subject: `Today’s performance, I Agree To The Terms, ${SESSION[user['session']].split(' @ ')[1]}, EST`, 
                html: 
                `
                Hello ${user['realName']}!
                <br /><br />
                We look forward to hosting you at today’s performance of The Builders Association’s <b><i>I Agree To The Terms</i></b>. I'm writing with links to access today’s show, starting at ${SESSION[user['session']].split(' @ ')[1]} Eastern.
                <br /><br />
                <b><i>I Agree To The Terms</i></b> is best experienced on a computer or laptop with Zoom, and a mobile device (smartphone or tablet). Please be sure to charge your devices, or have chargers handy!
                <br /><br />
                <b>Performance link</b>
                To join us today, please click this link on your computer: <a href='${PERFORMANCELINK[user['session']]}'>${PERFORMANCELINK[user['session']]}</a>
                <br /><br />
                <b>Mobile/App* access</b>
                When you join us in the room, you'll see instructions to join us on mobile. Please scan this QR code with your mobile device: 
                <br /><br />
                <img src='${user['qrCode']}' alt='' width='300' height='300'>
                <br /><br />
                If that’s giving you trouble, click <a href='https://show.iagreetotheterms.com/?email=${user['email']}&id=${user['id']}'>this link</a> on your mobile device.
                <br /><br />
                *please note: this is a web-based application; it will not require or request a download.
                <br /><br />
                Our workers will be there to help with any troubleshooting. Please let us know if you have any questions! We look forward to seeing you at the show!<br />
                <br />
                <img src='https://firebasestorage.googleapis.com/v0/b/i-agree-to-the-terms-2022.appspot.com/o/the-builders-logo.gif?alt=media&token=b22f2ec3-52c3-40d3-a842-29b7b9a183cc' alt='' width='165' height='165'>
                `
            }
        })
        let d = new Date();
        firebase.database().ref(`users/${user.uid}/lastSentDate`).set(d.toLocaleString('en-US', {timeZone: 'America/New_York'}));
    };

    const sendEmailToAll = (users) => {
        for(let i = 0; i < users.length; i++) {
            sendEmail(users[i]);
        }
    };

    const reset = (uid, email) => {
		firebase.database().ref(`users/${uid}/page`).set('new');
        firebase.database().ref(`users/${uid}/name`).set(email.split("@")[0]);
        // firebase.database().ref(`users/${uid}/lastSentDate`).set('-');
	};

    let sessionMenu =
        <Fragment>
            <Header as='h4'>Change Current Show Session</Header>
            <p>Please don't change it during the show or pre-show.</p>
            <Dropdown
                style={{width: '275px'}}
                selection
                options={sessions}
                value={props.currentSession}
                onChange={(e, {value}) => changeSession(value)}
            />
        </Fragment>;

    for(const [uid, user] of Object.entries(props.usersData)) {
        if(user['session'] !== undefined && user['session'] < SESSION.length) {
            sessionAudienceList[user['session']].push(
            {
                uid: uid,
                session: user['session'],
                email: user['email'],
                password: user['session'] >= 7 ? user['password'] : user['id'],
                realEmail: user['realEmail'],
                id: user['id'],
                realName: user['realName'],
                name: user['name'],
                status: user['page'],
                lastSentDate: user['lastSentDate'],
                qrCode: user['qrCode'],
            }
        )};
    }

    let output = [];
    for(let j = props.currentSession; j < SESSION.length; j++) {
        sessionAudienceList[j].sort((a,b) => (a.email > b.email) ? 1 : -1);
        let users = [];
        if(sessionAudienceList[j].length > 0) {
            for(let i = 0; i < sessionAudienceList[j].length; i++) {
                users.push(
                    <Table.Row>
                        <Table.Cell>{i + 1}</Table.Cell>
                        <Table.Cell>{sessionAudienceList[j][i]['email']}</Table.Cell>
                        <Table.Cell><span style={{fontSize: '9px'}}>{sessionAudienceList[j][i]['uid']}</span></Table.Cell>
                        <Table.Cell>{sessionAudienceList[j][i]['name']}</Table.Cell>
                        <Table.Cell>{sessionAudienceList[j][i]['status']}</Table.Cell>
                        <Table.Cell>
                            <Icon name='linkify' size='small' />
                            <a href={`https://show.iagreetotheterms.com/?email=${sessionAudienceList[j][i]['email']}&id=${sessionAudienceList[j][i]['password']}`}>Link</a>
                        </Table.Cell>
                        <Table.Cell>
                            <Icon name='download' size='small' />
                            <a href={sessionAudienceList[j][i]['qrCode']}>Download</a>
                        </Table.Cell>
                        <Table.Cell>
                            <Button
                                compact
                                size='tiny'
                                icon='mail'
                                content='Send'
                                onClick={() => sendEmail(sessionAudienceList[j][i])}
                            />
                        </Table.Cell>
                        <Table.Cell>{sessionAudienceList[j][i]['lastSentDate']}</Table.Cell>
                        <Table.Cell>
                            <Button
                                compact
                                size='tiny'
                                icon='remove'
                                content='Reset'
                                color='red'
                                onClick={() => reset(sessionAudienceList[j][i]['uid'], sessionAudienceList[j][i]['email'])}
                            />
                        </Table.Cell>
                    </Table.Row>
                );
            }
        }
        output.push(
            <Fragment>
                <Header as='h3'>Session {j} ({SESSION[j]})</Header>
                    <Table compact celled unstackable size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>UID</Table.HeaderCell>
                                <Table.HeaderCell>Preferred Name</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Auto Login</Table.HeaderCell>
                                <Table.HeaderCell>Login QR Code</Table.HeaderCell>
                                <Table.HeaderCell>Send Email</Table.HeaderCell>
                                <Table.HeaderCell>Last Sent</Table.HeaderCell>
                                <Table.HeaderCell>Reset</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {users}
                        </Table.Body>

                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='11'>
                                    <Button
                                        disabled={sessionAudienceList[j].length <= 0}
                                        compact
                                        icon='mail'
                                        content='Send Email to All'
                                        color='blue'
                                        onClick={() => sendEmailToAll(sessionAudienceList[j])}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                </Table>
            </Fragment>
        )
    }

    return (
        <Container>
            <Header as='h2' textAlign='center'>Audience List</Header>
            {sessionMenu}
            {output}
        </Container>
    )
}