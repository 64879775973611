import { useState, useEffect, Fragment } from 'react'
import { Button, Card, Header, Icon, Image, Table, Container, Segment } from "semantic-ui-react";

const EXCHANGERATEBASE = 49;

function createExchangeRateGoal() {
	let max = EXCHANGERATEBASE*5;
	let min = EXCHANGERATEBASE*0.11;
	return Math.random()*(max-min+1)+min;
}

export default function MetaMarketplace(props) {
    return (
        <Container text>
            <DigitalWalletBLDR money={props.money/EXCHANGERATEBASE} />
            <Header as='h3'>Trending Collections</Header>
            <Card.Group>
                <Card fluid color='green'>
                    <picture>
                        <source srcSet="./img/meta/rolex-watch.webp" type="image/webp" />
                        <img src='./img/meta/rolex-watch.jpg' width='100%'/>
                    </picture>
                    <Card.Content>
                        <Card.Header>
                            Dweller Luxury Watch
                        </Card.Header>
                        <Card.Meta>
                            <span className='date'>by Rolex</span>
                        </Card.Meta>
                        <p style={{fontSize: '14px', margin: '10px 0'}}>
                            The Datejust’s enduring aesthetics make it instantly recognizable. The characteristic shape of the Oyster case, the 18 ct gold fluted bezel, the Cyclops lens over the date and the five-piece link Jubilee bracelet – specially made for the model – all contributed to making this watch a classic. They include diamond-set or mother-of-pearl dials as well as Jubilee, palm and fluted laseretched motifs, which are applied to a traditional sunray finish for a particularly sophisticated effect, exclusive to the Datejust.
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='./img/bldrs-icon.png' alt='' height='20px' style={{ marginRight: '5px' }}/>
                            <p>105.00 BLDR</p>
                        </div>
                        <Button fluid disabled size='large' content='Sold Out'/>
                    </Card.Content>
                </Card>
                <Card fluid color='green'>
                    <picture>
                        <source srcSet="./img/meta/designer-handbag.webp" type="image/webp" />
                        <img src='./img/meta/designer-handbag.jpg' width='100%'/>
                    </picture>
                    <Card.Content>
                        <Card.Header>
                            Designer Handbag
                        </Card.Header>
                        <Card.Meta>
                            <span className='date'>by Chanel</span>
                        </Card.Meta>
                        <p style={{fontSize: '14px', margin: '10px 0'}}>
                            Chanel’s new handbag campaign, entitled “The Chanel Iconic”, shines the spotlight on one of the French house’s forever classics: the 11.12. Commonly referred to as the Chanel Flap bag, the quilted shoulder style takes the greenprint of the 2.55 bag and gives it a twist – literally. While the 2.55 features a rectangular lock, the 11.12 fastens shut with a double-C turn-style clasp. The 2.55’s chain-link strap is replaced with a metal chain interwoven with leather on a functional-yet-decorative day-to-day accessory, or an “accomplice”, as it’s affectionately described by the brand.
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='./img/bldrs-icon.png' alt='' height='20px' style={{ marginRight: '5px' }}/>
                            <p>95.00 BLDR</p>
                        </div>
                        <Button fluid disabled size='large' content='Sold Out'/>
                    </Card.Content>
                </Card>
                <Card fluid color='green'>
                    <picture>
                        <source srcSet="./img/meta/air-jordans.webp" type="image/webp" />
                        <img src='./img/meta/air-jordans.jpg' width='100%'/>
                    </picture>
                    <Card.Content>
                        <Card.Header>
                            Air Jordans
                        </Card.Header>
                        <Card.Meta>
                            <span className='date'>by Nike</span>
                        </Card.Meta>
                        <p style={{fontSize: '14px', margin: '10px 0'}}>
                            The Jordan 1 Retro Mid Red features a smooth leather upper with red overlays and Swooshes. At the collar, a debossed red Air Jordan Wings logo nods to the original Air Jordan 1 design. From there, a woven Jumpman tongue tag, white midsole, and red outsole complete the design.
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='./img/bldrs-icon.png' alt='' height='20px' style={{ marginRight: '5px' }}/>
                            <p>25.00 BLDR</p>
                        </div>
                        <Button fluid disabled size='large' content='Sold Out'/>
                    </Card.Content>
                </Card>
                <Card fluid color='green'>
                    <Image src='./img/meta/d-g-dress.jpg' wrapped ui={false} />
                    <Card.Content>
                        <Card.Header>
                            D&G Dress
                        </Card.Header>
                        <Card.Meta>
                            <span className='date'>by D&G</span>
                        </Card.Meta>
                        <p style={{fontSize: '14px', margin: '10px 0'}}>
                            Inspired by the cyber-subculture that lives in virtual reality, this runway-featured dress is made from fabric that resembles melted gold.
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='./img/bldrs-icon.png' alt='' height='20px' style={{ marginRight: '5px' }}/>
                            <p>70.00 BLDR</p>
                        </div>
                        <Button fluid disabled size='large' color='green' content="You don't have enough Builders Coins"/>
                    </Card.Content>
                </Card>
                <Card fluid color='green'>
                    <Image src='./img/meta/car.jpg' wrapped ui={false} />
                    <Card.Content>
                        <Card.Header>
                            Supercar
                        </Card.Header>
                        <Card.Meta>
                            <span className='date'>by Audi</span>
                        </Card.Meta>
                        <p style={{fontSize: '14px', margin: '10px 0'}}>
                            The Audi R8 performance Coupe brings the racing-inspired performance you seek with uncompromised styling. This is your opportunity to enjoy the exhilarating performance that lies within. Eye-catching styling, minimalist cabin still feels luxurious, roaring V-10 powertrain. The R8 wears a supercar wardrobe but is as approachable and easy to drive as any other Audi. As Audi's top model, the R8 sports car boasts a howling 602-hp V-10 engine just behind its snug two-seat cabin. It shares a lot with the Lamborghini Huracán, but the R8 is less flamboyantly styled. Buyers seeking a supercar that they can drive daily may find the Audi's refinement appealing.
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='./img/bldrs-icon.png' alt='' height='20px' style={{ marginRight: '5px' }}/>
                            <p>200.00 BLDR</p>
                        </div>
                        <Button fluid disabled size='large' color='green' content="You don't have enough Builders Coins"/>
                    </Card.Content>
                </Card>
                <Card fluid color='green'>
                    <Image src='./img/meta/shoes.jpg' wrapped ui={false} />
                    <Card.Content>
                        <Card.Header>
                            Louboutin
                        </Card.Header>
                        <Card.Meta>
                            <span className='date'>by Louboutin</span>
                        </Card.Meta>
                        <p style={{fontSize: '14px', margin: '10px 0'}}>
                            A Louboutin icon, the New Very Privé pump embodies the glamour of 1950s pin-ups and the daring of burlesque. A curved décolleté gently encases the foot, plunging down to a revealing toe cleavage that finishes in a sensual peep toe. The upper is incorporated into a concealed platform and teeters vertiginously over a 120 mm stiletto heel. This season’s offering comes in vampish glossy black patent leather, lined with signature red.
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='./img/bldrs-icon.png' alt='' height='20px' style={{ marginRight: '5px' }}/>
                            <p>80.00 BLDR</p>
                        </div>
                        <Button fluid disabled size='large' color='green' content="You don't have enough Builders Coins"/>
                    </Card.Content>
                </Card>
                <Card fluid color='green'>
                    <Image src='./img/meta/human-heart.jpg' wrapped ui={false} />
                    <Card.Content>
                        <Card.Header>
                            Human Heart
                        </Card.Header>
                        <Card.Meta>
                            <span className='date'>by 3D Master</span>
                        </Card.Meta>
                        <p style={{fontSize: '14px', margin: '10px 0'}}>
                            Multi-purpose 3D model of Heart, Perfect for Games, Renders, Scenes and etc. Easy to use, nothing extra to open it, no special plugin etc; just drag & drop, easy meshsmothing, real size, correctly scaled.
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='./img/bldrs-icon.png' alt='' height='20px' style={{ marginRight: '5px' }}/>
                            <p>40.00 BLDR</p>
                        </div>
                        <Button fluid disabled size='large' color='green' content="You don't have enough Builders Coins"/>
                    </Card.Content>
                </Card>
                <Card fluid color='green'>
                    <Image src='./img/meta/gucci.jpg' wrapped ui={false} />
                    <Card.Content>
                        <Card.Header>
                            SUPERGUCCI
                        </Card.Header>
                        <Card.Meta>
                            <span className='date'>by Gucci</span>
                        </Card.Meta>
                        <p style={{fontSize: '14px', margin: '10px 0'}}>
                            A groundbreaking collaboration between Gucci and Superplastic, SUPERGUCCI is a three-part series of ultra-limited NFTs co-created by Gucci's Creative Director Alessandro Michele and synthetic artists Janky & Guggimon. Each NFT incorporates House codes found in the Gucci Aria collection, and comes with an exclusive 8-inch tall white ceramic SUPERGUCCI SuperJanky sculpture, hand-crafted by ceramicists in Italy.
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='./img/bldrs-icon.png' alt='' height='20px' style={{ marginRight: '5px' }}/>
                            <p>50.00 BLDR</p>
                        </div>
                        <Button fluid disabled size='large' color='green' content="You don't have enough Builders Coins"/>
                    </Card.Content>
                </Card>
            </Card.Group>
        </Container>
    )
}


function DigitalWalletBLDR(props) {
	const [exchangeRate, setExchangeRate] = useState(EXCHANGERATEBASE);
	const [exchangeRateGoal, setExchangeRateGoal] = useState(createExchangeRateGoal());
	const [isExchangeRateGrowing, setIsExchangeRateGrowing] = useState(true);

	const money = Number.parseFloat(props.money).toFixed(3);
	const usd = Number.parseFloat(props.money*exchangeRate).toFixed(2);
	const percent = props.money === 0 ? 0.00 : Number.parseFloat((usd-props.money*EXCHANGERATEBASE)/(props.money*EXCHANGERATEBASE)*100).toFixed(2);
	const percentText = percent >= 0 ? <span style={{color: 'green'}}>+{percent}%</span> : <span style={{color: 'red'}}>{percent}%</span>;

	useEffect(() => {
		const timer = setTimeout(() => {
			if(isExchangeRateGrowing) {
				if(exchangeRateGoal >= exchangeRate) {
					setExchangeRate(exchangeRate + Math.random()*EXCHANGERATEBASE*0.1);
				} else {
					let newRateGoal = createExchangeRateGoal();
					setExchangeRateGoal(newRateGoal);
					setIsExchangeRateGrowing(newRateGoal>=exchangeRate);
					setExchangeRate(exchangeRate - Math.random()*EXCHANGERATEBASE*0.1);
				}
			} else {
				if(exchangeRateGoal < exchangeRate) {
					setExchangeRate(exchangeRate - Math.random()*EXCHANGERATEBASE*0.1);
				} else {
					let newRateGoal = createExchangeRateGoal();
					setExchangeRateGoal(newRateGoal);
					setIsExchangeRateGrowing(newRateGoal>=exchangeRate);
					setExchangeRate(exchangeRate + Math.random()*EXCHANGERATEBASE*0.1);
				}
			}
			// console.log(exchangeRate, exchangeRateGoal, isExchangeRateGrowing);
		}, 1000);
		return () => clearTimeout(timer);
	}, [exchangeRate]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Segment basic textAlign='center'>
			<img src='./img/bldrs-icon.png' alt='' height='100px'/>
			<p style={{margin: '0.1em', padding: 0}}>Builder’s Coins Balance</p>
			<Header size='huge' style={{margin: '0.1em', padding: 0}}>{money} BLDR</Header>
			<Header size='small' style={{margin: 0, padding: 0}}>USD ${usd} {percentText}</Header>
		</Segment>
	)
}