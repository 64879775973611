import { useState, useEffect, Fragment } from 'react'
import { Button, Header, Icon, Table, Container, Segment } from "semantic-ui-react";
import hitListData from '../data/hit-list-new.json';

let HITGROUPS = ['testing', 'general', 'qualification'];

export default function Lobby(props) {
	let hitItemLists = {};
	let i = 0;
	for (let group of HITGROUPS) {
		let hitItems = [];
		for (let value of hitListData[group]) {
			i += 1;
			hitItems.push(<HitItem
				key={value['no']}
				number={i}
				value={value}
				isQualificationHit={group === 'qualification'}
				isLocked={props.currentCueNumber === 20.5 ? true : props.currentCueNumber === 21 ? group === 'testing' ? false : true : false}
				isCompleted={props.userData.hitsCompleted.includes(value['no'])}
				isRejected={props.userData.hitsRejected.includes(value['no'])}
				isQualified={props.userData.qualificationHitsCompleted.length > value['bhm']}
				chageHit={props.changeHit}
			/>);
		}
		hitItemLists[group] = hitItems;
	}
	return (
		<Container>
			<DigitalWallet money={props.userData.money}/>
			<UserInfo userData={props.userData} />
			{/* Sample Hits */}
			<Header as='h3'>Sample HIT</Header>
			<Table compact striped selectable unstackable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2}>#</Table.HeaderCell>
						<Table.HeaderCell>Title</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{hitItemLists['testing']}
				</Table.Body>
			</Table>
			{/* General Hits */}
			<Header as='h3'>General HITs</Header>
			<Table compact striped selectable unstackable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2}>#</Table.HeaderCell>
						<Table.HeaderCell>Title</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{hitItemLists['general']}
				</Table.Body>
			</Table>
			{/* Qualification Hits */}
			<Header style={{marginBottom: '0.25em'}} as='h3'>Qualification HITs</Header>
			<p>Each HIT below earns you 1 BHM which helps unlock more HITs.</p>
			<Table compact striped selectable unstackable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2}>#</Table.HeaderCell>
						<Table.HeaderCell>Title</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{hitItemLists['qualification']}
				</Table.Body>
			</Table>
		</Container>
	)
}

function DigitalWallet(props) {
	return (
		<Segment basic textAlign='center'>
			<img src='./img/usd-icon.png' alt='' height='75px'/>
			<p style={{margin: '0.1em', padding: 0}}>Balance</p>
			<Header size='large' style={{marginTop: '0.1em', padding: 0}}>USD ${Number.parseFloat(props.money).toFixed(2)}</Header>
		</Segment>
	)
}

function UserInfo(props) {
	// console.log(props.userData);
	let approvalRate = Math.round(100-(100*(props.userData.hitsRejected.length-1)/(props.userData.hitsCompleted.length-1)));
	if(isNaN(approvalRate)) approvalRate = 0;
	
	return (
		<Fragment>
			<Table compact unstackable definition size='small' textAlign='center'>
					<Table.Body>
						<Table.Row>
							<Table.Cell width={6}><Icon name='user' />Name</Table.Cell>
							<Table.Cell>{props.userData.name}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><Icon name='id card' />Worker ID</Table.Cell>
							<Table.Cell>{props.userData.id}</Table.Cell>
						</Table.Row>
					</Table.Body>
			</Table>
			<Table unstackable textAlign='center' >
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Approval Rate</Table.HeaderCell>
						<Table.HeaderCell>#HITS Completed</Table.HeaderCell>
						<Table.HeaderCell>#HITS Rejected</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Table.Row warning textAlign='center'>
						<Table.Cell><Icon name='checkmark' />{`${approvalRate}%`}</Table.Cell>
						<Table.Cell><Icon name='inbox' /> {props.userData.hitsCompleted.length-1}</Table.Cell>
						<Table.Cell><Icon name='close' />{props.userData.hitsRejected.length-1}</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</Fragment>
	)
}

function HitItem(props) {
	const qualification = props.value['bhm'] > 0 ? props.isQualified ? <Icon size='small' name='lock open' /> : <Icon size='small' name='lock' /> : null;
	const reward = props.value['reward'] === 0 ? null : <span style={{fontSize: '12px', opacity: '60%'}}> (${Number.parseFloat(props.value['reward']).toFixed(2)}{props.isQualificationHit ? '+ 1BHM' : null})</span>;
	const button = props.value['is-ghosted'] ? <Button size='small' compact disabled content='Accept' color='yellow' /> :
		props.isRejected ? <Button size='small' compact disabled content='Rejected' color='red' /> :
		props.isCompleted ? <Button size='small' compact disabled content='Finished' color='grey' /> :
		props.isLocked || (props.value['bhm'] > 0 && !props.isQualified) ? <Button size='small' compact disabled content='Accept' color='yellow' /> :
		<Button size='small' compact content='Accept' color='yellow' onClick={() => props.chageHit(props.value['no'])}/>
	
	return (
		<Table.Row>
			<Table.Cell>{props.number}</Table.Cell>
			{/* <Table.Cell>{props.value['is-ghosted'] ? <Icon name='signal' color='red' /> : <Icon name='signal' color='green' />}</Table.Cell> */}
			<Table.Cell>
				{props.isCompleted ? props.isRejected ? <Icon name='close' color='red' /> :
					<Icon name='check' color='green' /> : null}
				{qualification}{props.value['title']}{reward}
			</Table.Cell>
			<Table.Cell textAlign='right'>{button}</Table.Cell>
		</Table.Row>
	)
}