import { useState, useEffect, useRef } from 'react';
import '../css/BuildersBBS.css';

const BBSDATA = {
    directory: [
        {
            fileName: '1.TXT',
            title: 'CBBS-1989-THE FUTURE OF PCS',
            text: 
                <p>
                    <br />
                    Msg 41654 is 23 line() on 04/07/89 from WARD CHRISTENSEN to ALL re: THE FUTURE OF PCS<br />
                    <br />
                    What do you think the future of PCs is?<br />
                    I see laptops going "every which way" - there's a market for "smaller, lighter, more portable", and also a marketplace for "my one big powerful machine that I take with me".<br />
                    Mark Eppley, Prez of Travelling Software, says that this will be the year for the Pocket PC. I can't wait!<br />
                    I would love to be able to do my reports, and write (text, not publish) a newsletter while commuting on the train.<br />
                    Who knows what will become the standard MEDIA - I hear Zenith is going fot the 2" diskette! (or was it 2.5"?). Others are going for software cartridges. - silicon, etc.<br />
                    My REAL requirement is: (1) to have the ctrl key left of A; and (2) to (related to 1) be able to run my favorite editor on it.<br />
                    I could get by with 256K, actually, and a 2.5" diskette, and a "typeable" keyboard, though I would be in the minority with such meager requirements.<br />
                    -<br />
                    Curious for your ideas and opinions.<br />
                    What machine do each of YOU see in YOUR future? I think I'm more interested in true portability, and not as interested (probably because of the investment) in "high end" stuff - my 10mhz 0-wait state machine is going to hold me for a while, though I could use more disk, or better organization of my disk.<br />
                    <br />
                    Msg 41658 is 30 line(s) on 04/08/89 from PAUL STREETER to ALL re: R/ PREDICTIONS<br />
                    <br />
                    1. Unless the METRA line you use is a lot smoother than the Burlington, which I used to ride, you won't be able to do much work on the train, even with a pocket computer. (Someone prove me wromg by saying how many people already work with laptops on the train. I could never concentrate enough to do anything useful during the ride.)<br />
                    2. CPM machines and CPM shops won't die and a lot of obsolete machines will be made and purchased by people who realize they are the most cost effective for most tasks.<br />
                    3. A useful pocket cumputer would haqve to have some kind of keyboard technology that I can't imagine in order to be a good idea for most of us, but massive memory will be easy.<br />
                    4. Flat screens for color VGA/EGA should be possible now, if they are not on the market already.<br />
                    5. My best prediction is that laptops with a 12 Mhz 286 r 386, 40 Mb or larger disk capacity, LPT1, LPT2, COM1, and COM2 ports built in and at least 2 short slots and one full length slot will become affordable as an alternative to a desk top computer.<br />
                    10. Someone will introduce an envelope feeder of production capacity for "normal" dot matrix printer. The repeatability of the registration will be 1/8 " or better.<br />
                    My introduction to computers was via a Honeywell 400 (made by Honeywell, not GE). It was upgraded to its maximum memory of 32 K just after I started. It had 3 phase power to a motor generator for its power supply, 3 tons of air conditiioning with 3 tons backup, a CE on site 8 hours a day, and took 1 hour to compile 1000 lines of COBOL vs 1 min on PC.<br />
                    <br />
                    <br />
                    Msg 41663 is 20 line(s) on 04/09/89 from ERIC BOHLMAN to PAUL STREETER re: R/ PREDICTIONS<br />
                    <br />
                    Pocket computer keyboards: If the original "Star Trek" were being produced now, the producers would have to come up with some rational-sounding explanation for how data gets entered into tricorders (probably mind-reading).<br />
                    When the show was originally done, pocket computers were far enough into the future that viewers wouldn't thing of questioning it. In the late devices that would allow data entry by pressing combinations of six or so switches, but they never caught on. Maybe a tiny trackball to pick out letters (seriously, a friend who has very limited use of his hands uses that on a Macintosh)? Voice entry might be possible, though it would be slow and I can imagine a bunch of people on a train getting their dictation crossed up (to say nothing with confidentiality problems; imagine two attorneys on opposite sides of a case getting the last two adjacent seats on the train, or the stockbroker whose computer "accidentally" listens in on some juicy inside information).<br />
                    &nbsp;
                </p>
        },
        {
            fileName: '2.TXT',
            title: 'CBBS-1991-STATIC ZAP',
            text:
                <p>
                    <br />
                    Msg 45178 is 24 line(s) on 10/19/91 from WARD CHRISTENSEN to ALL re: STATIC ZAP AGAIN!<br />
                    <br />
                    Well, it is that time of year again - the dry season in the north - and that means static.<br />
                    A couple years ago (mebbe '87?) I blew out the serial port of my Toshiba T1100+ when I connected the LapLink cable to my desktop - when the low power light turned on and I reached in back to plug in the AC adapter, I zapped static to the case, and it grounded the only place it could find - thru the RS-232 cable.<br />
                    My Wizard 5200 hadn't been backed up in a month or so.<br />
                    ZZZZZZZZZaaaap.<br />
                    Static blew the wizard, AND MY "NEW" T1000 XE serial port! I tried hooking the XE via Lap Link to a machine, no go. Tried a commo program port to port - it would receive but not send.<br />
                    Tried a different machine AND a different Wizard link cable (hoping only the cable (which has electronics in it) got zapped - nope, the Wizard's xfer circuitry is gone.<br />
                    You have one VERY UNHAPPY person here, sigh.<br />
                    P.S. I noticed my car was zapping me every time I got out of it today as I went shopping. Also I reached to turn on a fluorescent in the basememt and it FLASHED from the STATIC ZAP before I got to the switch!<br />
                    I should be forbidden to work with computers!<br />
                    Heck, my sweatshirt is 63% cotton, my pants - I think 100% (jeans)!<br />
                    Aaaaaargh<br />
                    &nbsp;
                </p>
        },
        {
            fileName: '3.TXT',
            title: 'MODEM ADDICTION',
            text:
                <p>
                    <br />
                    - by Steve King -<br />
                    <br />
                    "Did you know that last month's (expletive) phone bill is over $450?" my wife scolded me in her harshest, my-husband-the-child voice. "That's more than twice the monthly payment you make for that (expletive) computer!" she continued as she escalated to screaming.<br />
                    <br />
                    "I confess!  I confess!" I sobbed. "I'm just an on-line junkie -- I'm addicted to my modem!  I guess I'll just have to join Modems Anonymous before I owe my soul to the phone company."<br />
                    <br />
                    As a counselor for Modems Anonymous, I hear numerous variations of the preceding story every day. That insidious disease, modem fever, is exacting a tragically large toll from the cream of our society's computer users.<br />
                    <br />Modem-mania is sweeping through the very foundations of our country and there seems to be no stopping it. This disease (yes, it is a social disease of almost epidemic proportions) is becoming a such calamity that soon there's even going to be a soap opera about on-line addiction named, "All My Modems."<br />
                    <br />
                    If you don't already own one of those evil instruments called a modem, take warning!  Don't even think about buying one. Modem fever sets in very quietly; it sneaks up on you and then grabs you by the wallet, checkbook or, heaven forbid, credit cards.<br />
                    <br />
                    Once you own a modem, you enter the insidious addictive trap by "dialing up" a friend who also has a modem. For some strange reason, typing messages to each other fascinates you. (Even if it is less than 10% of the speed that you can speak the same words over a normal voice phone link.) Of course, you make several attempts at hooking up before you finally figure out that at least one of you must be in the half-duplex mode; that discovery actually titillates you (sounds impossible, but it's true).<br />
                    <br />
                    Then your modem-buddy (friend is too good a term) sews another seed on the road to on-line addiction by giving you the number of a local RBBS (Remote Bulletin Board Service). Once you get an RBBS phone number, you've taken the first fatal step in a journey that can only end in on-line addiction.<br />
                    <br />
                    After you take the next step by dialing up the the RBBS your modem-buddy told you about, you find that it's very easy to "log-on." This weird form of conversation with an unattended computer is strangely exciting, much more so than just typing messages when you're on-line with your modem-buddy. The initial bulletins scroll by and inform you about the board, but you're too "up" to comprehend most of it. Then you read some of the messages in the message section and maybe, in a tenative manner, you enter one or two of your own.<br />
                    That's fun, but the excitement starts to wear off; you're calming down.<br />
                    Thinking that it might be worthwhile to go back and re-read the log-on bulletins, you return to the main RBBS menu.<br />
                    <br />
                    Then it happens. The RBBS provides the bait that entices you all the way into the fiery hell of modem addiction. As you look at the RBBS main menu to learn how to return to the log-on bulletins, you find an item called FILES. By asking your host computer for FILES, you thread the bait onto the hook of corruption; the FILES SUBMENU sets the hook. You start running with the line when you LIST the files; you leap into the air with the sheer joy of the fight when all those public domain program titles and descriptions scroll by.<br />
                    They're FREE!!!  All you have to do is tell the bulletin board to download (transmit) them to you. You download your first program and you're landed, in the creel, cleaned and ready for the cooking fires. In just 55 minutes after you logged-onto the board, you've downloaded six programs, one of them is Andrew Fleugelman's PC-Talk, version 3 (truly an instrument for evil).
                    <br />
                    RBBSLIST.DOC, which is also among the files you downloaded, contains a list of a great number of bulletin boards throughout the country. (There's evil all around us, constantly tempting us!) You print the list and find about 60 RBBS phone numbers. (Have mercy on our souls!) The list also gives you the hours of operation, communications parameters and informs you about each board's specialty. You decide to try PC-Talk and use it to dial-up an RBBS about three states away. Since the line is busy, you pass the time entering all those RBBS phone numbers into PC-Talk's voluminous dialing directory.<br />
                    <br />
                    You try the number again -- still busy. You think, "Hey, there's one that specializes in Pascal programs. Maybe I'l try it. It's about half-way across the country, but it's after 5pm and the phone rates have changed. It won't be too expensive."<br />
                    <br />
                    The Pascal board answers. After 45 minutes you've downloaded another five programs. Then you call another board -- only this one's completely across the country from California, in Florida. And so it goes on into the night... And the next night... And the next...<br />
                    <br />
                    Some days it gets to you. You begin to feel the dirtiness of modem addiction, particularly when your wife makes you feel like a child by berating you for those astronomical phone bills -- if she hasn't divorced you by then. Every time you sit down before your IBM PC to do some work, you dial up another RBBS instead. If that one's busy, you call another, and another, until you connect. Then you feel OK, almost "high." When you finally hang up, you still can't work; you can only dial up another RBBS.<br />
                    <br />
                    Your downfall as an on-line addict is just another one of this society's terrible tragedies, such as polygamy or the compulsion to circle all the numbers on computer magazine "bingo cards." Eventually your whole social life relies upon only the messages you find on electronic bulletin boards; your only happiness is the programs you have downloaded. (You never try any of them, you only collect them.)<br />
                    <br />
                    Hope exists, however. We, the dedicated but under-paid staff of Modems Anonymous, have done extensive research to find a cure for modem mania, which has been ruining hundreds of lives. And we have succeeded in our quest. The cure is really quite simple, yet effective:  Set up your own remote bulletin board service. Then all the other modem addicts will phone you, and their wives can nag at them about $450 phone bills. And you can find peace -- at last.<br />
                    <br />
                    <br />
                    Call The Works BBS - 1600+ Textfiles! - [914]/238-8195 - 300/1200 - Always Open<br />
                    &nbsp;
                </p>
        },
        {
            fileName: '4.TXT',
            title: 'CALL FOR DISCUSSION',
            text:
                <p>
                    <br />
                    From: edeeringporacle.com (Eric Deering)<br />
                    <br />
                    Subject: CALL FOR DISCUSSION: soc.culture.african.american Followup -To: news.groups<br />
                    <br />
                    Summary: Creating a new newsgroup: soc.culture.african.american Reply-To: edeering@ncar.ucar.edu (Eric Deering)<br />
                    <br />
                    Date: Tue, 13 Mar 90 01:42:07 GMT<br />
                    <br />
                    <br />
                    This is a formal CALL FOR DISCUSSION about the creation of an unmoderated group to discuss issues and events of concern to African Americans, as well as people of African descent residing in the general vicinity of United States (i.e. U.S., Canada, the Carribean [sic], etc . . .). The proposed name of this group will be Socculture.african.american, and it will be a forum of discussion for topics usually attributed to the reg­ular soc.culture groups (i.e. sports, music, politics, business, the media, history, the Arts, the sciences, etc .. .).<br />
                    <br />
                    Because of the great diversity of African descendents [sic] in the Americas, it is difficult to create a newsgroup that is all things to all people. The primary focus of this group will be towards those descendents [sic] of the African diaspora living in the United States and countries adjoining the United States (i.e. Canada and the Carribean [sic] islands). It is hoped that someday other newsgroups of a similar nature will be spun off from this one. So far, there has been a lot of interest in creating this group, via local discussion on soc.culture.african and various emailings. Right now the group is to be unmoderated unless someone would like to act as a moderator. Unfortunately, I cannot commit myself to the task, but if someone is willing, and people want a moderated group, please volunteer.<br />
                    <br />
                    As per the guidelines, discussion will last at least 2 weeks, the discus­sion being held on news.groups, NOT soc.culture.african. Please post ALL future comments to news.groups and not to me. Discussion will begin today, Monday, March 12, and will end on Tuesday, March 27 at midnight. Afterwards, a call for votes will be made by me.<br />
                    <br />
                    Thanks!<br />
                    &nbsp;
                </p>
        },
        {
            fileName: '5.TXT',
            title: 'AIDS INFO BBS (1988)',
            text:
                <p>
                    <br />
                    From: pearson<br />
                    Date: Jan 27 13:08:21 1988 (16 lines)<br />
                    Subject: Re: Hydrogen Peroxide cure<br />
                    Reply to item: 38<br />
                    Attn: stfrancis<br />
                    <br />
                    There are no supressed technologies, only fools and their money. I may agree that the wheels may turn too slowly (re. doing research, getting results into the hands of the front-line MD), but if something is valuable someone somewhere will build reputation and fortune on it.<br />
                    <br />
                    What good is a 200mph carborator if it fries your engine?<br />
                    <br />
                    What good is an AIDS cure if it kills you?<br />
                    <br />
                    <br />
                    From: pearson<br />
                    Date: Jan 29 01:23:26 1988 (13 lines)<br />
                    Subject: Re: Hydrogen Peroxide cure<br />
                    Reply to item: 43<br />
                    Attn: darrellbross<br />
                    <br />
                    Figure out how to put it into a pill and require a prescription.<br />
                    <br />
                    Or find a slightly different formula and patent it.<br />
                    <br />
                    If people didn't make money on cheap stuff, Walgreen's shelves would not be full of stuff like Bacatracin, Preperation H, or .. gosh..<br />
                    <br />
                    hydrogen peroxide.<br />
                    <br />
                    <br />
                    From: rastan<br />
                    Date: Jan 30 13:59:20 1988 (10 lines)<br />
                    Subject: symptoms<br />
                    <br />
                    I'm new to this informational BBS and I would like to know, WHAT are the symptoms of AIDS. I know that one gets black spots on the skin in some cases. But, what are the other symptoms that I and others should watch for?<br />
                    <br />
                    <br />
                    From: sysop<br />
                    Date: Jan 30 17:50:40 1988 (32 lines)<br />
                    Subject: Re: symptoms<br />
                    Reply to item: 44<br />
                    Attn: rastan<br />
                    <br />
                    Others may add to this list, but I think the symptoms of AIDS, like the symptoms of several other somas, cover a broad range of observation and are quite open to misinterpretation. Every symptom I have heard of sofar is also possibly the symptom of some other condition. The best test I know of is a thorough test for syphilis, and repeat it every once in a while. (Syphilis goes into "hiding" for long periods of time, and is not detectable at those times).<br />
                    <br />
                    <br />
                    From: nmadson<br />
                    Date: Jan 31 12:12:07 1988 (9 lines)<br />
                    Subject: HIV+ Support Group<br />
                    <br />
                    A support group for HIV+ individuals is currently in formation. We expect to be meeting every other Wednesday night in San Francisco. For more information leave a message here or voice phone #(415) 929-8004.<br />
                    <br />
                    <br />
                    From: docstanley<br />
                    Date: Jan 31 19:11:22 1988 (11 lines)<br />
                    Subject: Co-op Hospice for Marin County<br />
                    <br />
                    I have a piece of property in Bolinas, Ca on which I wish to develop a co-op AIDS Hospice for pwas who want to spend their last days in a rural, Marin County environment rather than S.F. General. Leave E-mail "Headlands" project, on this bbs. Thanks.<br />
                    &nbsp;
                </p>
        }
    ],

    cues: [
        {
            text:
                <p>
                    <br />
                    -<br />
                    Msg 4344 on Monday April 6, 1980 from BOB GRAF<br />
                    to ALL subject: 80 SERIES HELP<br />
                    Hello all -<br />
                    Can anyone suggest a good book or informational document detailing<br />
                    the instruction set for the Intel 80 series micros?<br />
                    <br />
                    -<br />
                    Msg 4346  on Wednesday April 8, 1980 from STEVE O’NEILL<br />
                    I've been using "The eighty eighty-six book" but I bought it two years ago, so there should be something newer.<br />
                    <br />
                    -<br />
                    Msg 4349 from TONY BISCOTTI<br />
                    Help!<br />
                    I’m looking to set a radio shack data terminal to twelve hundred baud.<br />
                    Nobody at the Shack knows anything beyond "it's old"...<br />
                    <br />
                    -<br />
                    Msg 4352 from WARD CHRISTIANSEN<br />
                    My father blacked out in the bathroom this am, fell, broke his leg.<br />
                    Probably going up there Saturday. Doing taxes Friday.<br />
                    <br />
                    -<br />
                    Msg 4359 from TONY BISCOTTI<br />
                    Infoworks publishing put out a good 286/386 book last year. If I can find it I’ll repost with more info later.<br />
                    <br />
                    -<br />
                    Msg 4362 from WARD CHRISTIANSEN<br />
                    Well, Dad seems to be coming and going about the mental aspects --interesting in a way. One day he's crying about all the kids killed in car accidents "they just go too fast". What triggered that? Something on TV?  (no. it’s basic cable; he only gets weather.)<br />
                    <br />
                    -<br />
                    Msg 4370 from BOB GRAF<br />
                    Ward; Sorry to her about your Dad, my mother passed away in March after a 6 month 'bout with cancer (she was fine before that) It hurts.<br />
                    I feel for you, am available to help in any way.<br />
                    Bob<br />
                    <br />
                    -<br />
                    Msg 4378 from ELLIOTT FRANCIS<br />
                    I would like to introduce myself. i'm new to Chicago computer systems, i just moved from Boise, ID and i would love mail about anything. I love to dance, sing, draw, write and i read comic books. I'm 22 and i graduated from Hyde Park (Go Indians!) and if anyone has anything interesting to say, leave mail. See YA!<br />
                    &nbsp;
                </p>
        },
        {
            text: <img src='./img/bbs/manifesto-1.png' width='100%' height='auto'/>
        },
        {
            text:
                <p>
                    <br />
                    The Computer Underground Digest, or, C.U.D.<br />
                    From: Liz E. Borden<br />
                    Subject: Sexism in C.U.D.<br />
                    Thursday 8 Jan 1989<br />
                    I’d like to open up a conversation about cybersexism here on the board and in the wider hacker community. Less than five percent of pirates are female, and probably less than one percent are phreaks or hackers. This skewed participation is importing the male culture and values into this new world. Isn't it ironic that in this new world where "a million flowers could bloom" almost all the emerging subcultures are male?<br />
                    <br />
                    From: Owen M. Higgins:<br />
                    Thursday January 8th 1989<br />
                    I don't get it.  Not enough female "Pirates and phreaks"? Are you saying you think more women should be involved in illegal activities?<br />
                    <br />
                    From: Hayley Carr:<br />
                    Thursday January 8th 1989<br />
                    Sexism is rampant all over the net. The alt.sex stuff—bondage, gifs, what-have-you —appeal to male fantasies of a type that degrades women.<br />
                    <br />
                    From: Liz E. Borden:<br />
                    Thursday January 8th 1989<br />
                    BBSs in general, especially those catering to adolescents and college students, are frightening in their misogyny. Even on general posts, on any subject, women are referred to with terms such as broads, bitches, cunts. There is just constant hostility against women as a class.<br />
                    &nbsp;
                </p>
        },
        {
            text: <img src='./img/bbs/manifesto-2.png' width='100%' height='auto'/>
        },
        {
            text:
                <p>
                    <br />
                    THE WELL, Msg on Sunday 05/14/1989 from MAUREEN CALLAHAN<br />
                    Some news from North Berkeley... after over twelve years of searching, I've located the woman who gave birth to me and put me up for adoption. Early this evening, I was able to search a database index of the Riverside, CA phone book archive. She's 69 years old. She's my mother. I'm in a bit of a daze.<br />
                    <br />
                    THE WELL, Msg on Sunday 05/14/1989 from MICHAEL BOYD<br />
                    go for it mo ! Yes, yes, yes! Make that call! I'll be happy to come over and hold the handset for you if you need both hands to dial.<br />
                    <br />
                    THE WELL, Msg on Sunday 05/14/1989 from MAUREEN CALLAHAN<br />
                    Yes, I will call her and tell her who I am. With ten taps on a phone I might hear her voice for the first time in forty-four years.<br />
                    <br />
                    THE WELL, Msg on Sunday 05/14/1989 from MARY KRUEGER<br />
                    Good luck, Maurice. I've got my fingers crossed for you and my breath baited<br />
                    Ten little taps!<br />
                    <br />
                    THE WELL, Msg on Sunday 05/14/1989 from MAUREEN CALLAHAN<br />
                    My momma. She said "Oh my god, I died and went to heaven." And then it got better. You can't imagine how happy I am. She asked if my parents were still alive, I told her they're both dead and she said "Don't worry, you're gonna have more family than you'll know what to do with..." She’s calling me back soon after she tells her daughter. I have a sister!!! I am so happy. Thanks again everyone. My love is spilling over to you all.<br />
                    &nbsp;
                </p>
        },
        {
            text: <img src='./img/bbs/manifesto-3-new.png' width='100%' height='auto'/>
        },
        {
            text:
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src='./img/bbs/the-builders-logo.png' width='90%' height='auto' style={{paddingBottom: '10px'}}/>
                    <img src='./img/bbs/i-agree-logo.png' width='100%' height='auto' style={{paddingBottom: '40px'}}/>
                    <img src='./img/bbs/the-builders-bbs-is-closed.png' width='100%' height='auto'/>
                </div>
                
        }
    ]
}

export default function BuilderBBS(props) {
    const [page, setPage] = useState('larry');
    const [blink, setBlink] = useState(true);

    useInterval(() => {
		setBlink(!blink);
	}, 1000);

    let output = '';

    if(props.currentCueNumber === 17) {
        output = (
            <div className='bbs-container'>
                {BBSDATA['cues'][6]['text']}
            </div>
        );
    } else if(props.currentCueNumber === 11) {
        output = (
            <div className='bbs-container' style={{flexGrow: '99'}}>
                {BBSDATA['cues'][0]['text']}
            </div>
        );
    } else if(props.currentCueNumber === 12) {
        output = (
            <div className='bbs-container'>
                {BBSDATA['cues'][1]['text']}
            </div>
        );
    } else if(props.currentCueNumber === 13) {
        output = (
            <div className='bbs-container' style={{flexGrow: '99'}}>
                {BBSDATA['cues'][2]['text']}
            </div>
        );
    } else if(props.currentCueNumber === 14) {
        output = (
            <div className='bbs-container'>
                {BBSDATA['cues'][3]['text']}
            </div>
        );
    } else if(props.currentCueNumber === 15) {
        output = (
            <div className='bbs-container' style={{flexGrow: '99'}}>
                {BBSDATA['cues'][4]['text']}
            </div>
        );
    } else if(props.currentCueNumber === 16) {
        output = (
            <div className='bbs-container'>
                {BBSDATA['cues'][5]['text']}
            </div>
        );
    } else if(page === 'larry') {
        output =
            <div className='bbs-container'>
                <img src='./img/bbs/home.png' width='100%' height='auto' style={{paddingBottom: '10px'}}/>
                {
                    blink ?
                    <p onClick={() => setPage('init')} style={{textAlign: 'center', color: '#FFFFFF', opacity: '0'}}>
                        <br />
                        [PRESS HERE TO CONTINUE]<br />
                        &nbsp;
                    </p> :
                    <p onClick={() => setPage('init')} style={{textAlign: 'center', color: '#FFFFFF'}}>
                        <br />
                        [PRESS HERE TO CONTINUE]<br />
                        &nbsp;
                    </p>
                }
            </div>;
    } else if(page === 'init') {
        output = (
            <div className='bbs-container'>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src='./img/bbs/the-builders-logo.png' width='90%' height='auto' style={{paddingBottom: '10px'}}/>
                    <img src='./img/bbs/i-agree-logo.png' width='100%' height='auto'/>
                </div>
                <p style={{textAlign: 'center'}}>
                    &nbsp;<br />
                    AMIGA           IBM           CONSOLE<br />
                    Builders Network, More nodes Wanted!<br />
                    <br />
                    *-----------------------------------------*<br />
                    I N F O R M A T I O N   E X C H A N G E   B B S<br />
                    *-----------------------------------------*<br />
                    
                    +1-212-274-0446<br />
                    &nbsp;
                </p>
                <p style={{textAlign: 'center'}}>
                    ===========================================
                </p>
                <p style={{textAlign: 'center'}}>
                    &nbsp;<br />
                    Although this file has been scanned for all known virus's using the latest version of SCAN from McAfee and Associates, you are responsible for protecting yourself!<br />
                    <br />
                    BACKUP YOUR SYSTEM FILES OFTEN!<br />
                    &nbsp;
                </p>
                <p style={{textAlign: 'center'}}>
                ===========================================
                </p>
                {
                    blink ?
                    <p onClick={() => setPage('welcome')} style={{textAlign: 'center', color: '#FFFFFF', opacity: '0'}}>
                        <br />
                        [PRESS HERE TO CONTINUE]<br />
                        &nbsp;
                    </p> :
                    <p onClick={() => setPage('welcome')} style={{textAlign: 'center', color: '#FFFFFF'}}>
                        <br />
                        [PRESS HERE TO CONTINUE]<br />
                        &nbsp;
                    </p>
                }
            </div>
        );
    } else if(page === 'welcome') {
        output = (
            <div className='bbs-container'>
                <p>
                    <br />
                    WELCOME {props.name.toUpperCase()}:<br />
                    YOU FOUND THE BUILDERS!<br />
                    <br />
                </p>
                <p style={{textAlign: 'center'}}>
                    The BUILDERS BBS  WWIV<br />
                    running WWIV 4.23<br />
                    Lockjaw the Ogre, Sysop<br />
                    <br />
                </p>
                <p>
                    The BUILDERS BBS WWIV aims at a wide range of BBSers. Message fans will enjoy the myriad of topics available in both local and networked message areas. File seekers will find a wide variety of utilities, text files and fun stuff. Game players will enjoy the doors area, which is constantly being updated and added to. In addition, there are several specific interest areas available.<br />
                    <br />
                    Ham Radio operators might want to check out the Ham message and files.<br />
                    <br />
                    OS/2 Users will enjoy the Messages and the Hobbes OS/2 CD-Rom Online.<br />
                    <br />
                    History buffs will like the historical texts to read online or download.<br />
                    <br />
                    The BUILDERS BBS serves as a distribution point for Project Gutenburg E-Texts, a project consisting of volunteers who produce plain text versions of many public domain and special availability books and documents. Download a copy of Lincoln's Inaugural addresses, The King James version of The Bible, The US Constitution, The War of the Worlds, Peter Pan, The CIA World Factbook or even the Complete Works of Shakespere among MANY others!<br />
                    <br />
                    The BUILDERS BBS is an Administration and Support board for FIITAnet, aregional WWIV based network that is branching out to include other locations nationwide. FIITAnet is a friendly network with active message areas available and plenty more. Ask the sysop for information.<br />
                    <br />
                    The BUILDERS strives to collect a wide range of Electronic Serials and E-Zines, along with interesting text files for users to download or read online.<br />
                    <br />
                    WELCOME.<br />
                    <br />
                    The BUILDERS BBS<br />
                    300-14400 USR Sportster<br />
                    FIITAnet @1000<br />
                    WWIVnet @5555<br />
                    FILEnet @1021&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lockjaw the Ogre<br />
                    VirtualNet @19199995&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;welcomes you.<br />
                    DeltaLink @1<br />
                    HOGnet @6969<br />
                    &nbsp;
                </p>
                {
                    blink ?
                    <p onClick={() => setPage('directory')} style={{textAlign: 'center', color: '#FFFFFF', opacity: '0'}}>
                        <br />
                        [PRESS HERE TO CONTINUE]<br />
                        &nbsp;
                    </p> :
                    <p onClick={() => setPage('directory')} style={{textAlign: 'center', color: '#FFFFFF'}}>
                        <br />
                        [PRESS HERE TO CONTINUE]<br />
                        &nbsp;
                    </p>
                }
            </div>
        );
    } else if(props.currentCueNumber === 10) {
        if(page === 'directory') {
            const files = BBSDATA['directory'].map((file, index) => {
                return (
                    <div onClick={() => setPage(index)} className='file-item'>
                        <p>
                            [{file['fileName']}&nbsp;&nbsp;&nbsp;&nbsp;{file['title']}
                        </p>
                        <p>
                            ]
                        </p>
                    </div>
                )
            });
            output = (
                <div className='bbs-container' style={{flexGrow: '99'}}>
                    <p>
                        <br />
                        Builders BBS Directory<br />
                        <br />
                        The Builders have drawn from textfiles.com and queerdigitalhistory.com to bring you a selection of their archives of BBS message boards.<br />
                        Please feel free to dig in as we wait for the show to begin.<br />
                        &nbsp;
                    </p>
                    <div style={{backgroundColor: '#00FF00', color: '#000000'}}>
                        <p>Filename&nbsp;&nbsp;Description</p>
                    </div>
                    {files}
                </div>
            );
        } else if(0 <= parseInt(page) < BBSDATA['directory'].length) {
            output = (
                <div className='bbs-container' style={{flexGrow: '99'}}>
                    <p onClick={() => setPage('directory')} style={{color: '#FFFFFF'}}>
                        <br />
                        [BACK]<br />
                        &nbsp;
                    </p>
                    {BBSDATA['directory'][parseInt(page)]['text']}
                    {
                        blink ?
                        <p onClick={() => setPage('directory')} style={{textAlign: 'center', color: '#FFFFFF', opacity: '0'}}>
                            <br />
                            [BACK TO DIRECTORY]<br />
                            &nbsp;
                        </p> :
                        <p onClick={() => setPage('directory')} style={{textAlign: 'center', color: '#FFFFFF'}}>
                            <br />
                            [BACK TO DIRECTORY]<br />
                            &nbsp;
                        </p>
                    }
                </div>
            );
        }
    } 

    return (
        <div className='bbs'>
            {output}
        </div>
    )
}

function useInterval(callback, delay) {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	});

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}